import React from 'react';
import classNames from 'classnames';
import {SectionTilesProps} from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

class News extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'news section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'news-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
      'tiles-wrap',
      pushLeft && 'push-left'
    );

    const sectionHeader = {
      title: '@cesurberlin',
      // paragraph: ''
    };

    return (
      <section
        {...props}
        className={outerClasses}
        style={{backgroundColor: "#F5F3F4"}}
      >
        <div className="container">
          <div className={innerClasses} style={{ paddingBottom: "108px" }}>
            <a href="https://www.instagram.com/cesurberlin/" target="_blank" rel="noopener noreferrer">
            <SectionHeader data={sectionHeader} className="center-content reveal-from-bottom" />
            </a>
            <div className={tilesClasses}>
{/* 
              <div className="tiles-item reveal-from-bottom">
                <div>
                </div>
              </div> */}

              <div className="tiles-item reveal-from-bottom">
                <div className="tiles-item-inner has-shadow">
                  <figure className="news-item-image m-0">
                  <a href="https://www.instagram.com/cesurberlin/" target="_blank" rel="noopener noreferrer">
                    <Image
                      src={require('./../../assets/images/3rd-party/insta-03.jpg')}
                      alt="Platzhalter"/>
                      {/* 344 x 294 */}
                  </a>
                  </figure>
                </div>
              </div>

              <div className="tiles-item reveal-from-bottom" data-reveal-delay="100">
                <div className="tiles-item-inner has-shadow">
                  <figure className="news-item-image m-0">
                  <a href="https://www.instagram.com/cesurberlin/" target="_blank" rel="noopener noreferrer">
                    <Image
                      src={require('./../../assets/images/3rd-party/insta-01.jpg')}
                      alt="Platzhalter" />
                  </a>
                  </figure>
                </div>
              </div>

              <div className="tiles-item reveal-from-bottom" data-reveal-delay="100">
                <div className="tiles-item-inner has-shadow">
                  <figure className="news-item-image m-0">
                  <a href="https://www.instagram.com/cesurberlin/" target="_blank" rel="noopener noreferrer">
                    <Image
                      src={require('./../../assets/images/3rd-party/insta-02.jpg')}
                      alt="Platzhalter" />
                  </a>
                  </figure>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    );
  }
}

News.propTypes = propTypes;
News.defaultProps = defaultProps;

export default News;
