import React from 'react';
import classNames from 'classnames';
import {SectionSplitProps} from '../../utils/SectionProps';
import {Link} from 'react-router-dom';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

class FeaturesSplitHome extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'features-split section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'features-split-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const splitClasses = classNames(
      'split-wrap',
      invertMobile && 'invert-mobile',
      invertDesktop && 'invert-desktop',
      alignTop && 'align-top'
    );

    const sectionHeader = {
      title: 'Great skin does not happen by chance but by appointment.',
      paragraph: ''
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />

          <div className="center-content">
          <Link to="/treatments/specials" style={{ textDecoration: "none", color: "#707070", marginLeft: "10px" }}>
                      CESUR SPECIALS
            </Link>

            <br></br>

            <Link to="/treatments/hydrafacial" style={{ textDecoration: "none", color: "#707070", marginLeft: "10px" }}>
                      HYDRAFACIAL
            </Link>
            <b style={{ textDecoration: "none", color: "#707070", marginLeft: "10px" }}>
                      |
            </b>

            <Link to="/treatments/needeling" style={{ textDecoration: "none", color: "#707070", marginLeft: "10px" }}>
                      MICRO NEEDELING
            </Link>
            <b style={{ textDecoration: "none", color: "#707070", marginLeft: "10px" }}>
                      |
            </b>

            <Link to="/treatments/permanent" style={{ textDecoration: "none", color: "#707070", marginLeft: "10px" }}>
                      PERMANENT MAKE-UP
            </Link>
            <b style={{ textDecoration: "none", color: "#707070", marginLeft: "10px" }}>
                      |
            </b>

            <br></br>

            <Link to="/treatments/microdermabrasion" style={{ textDecoration: "none", color: "#707070", marginLeft: "10px" }}>
                      DIAMANT MICRODERMABRASION
            </Link>
            <b style={{ textDecoration: "none", color: "#707070", marginLeft: "10px" }}>
                      |
            </b>

            <Link to="/treatments/mesoproationen" style={{ textDecoration: "none", color: "#707070", marginLeft: "10px" }}>
                      NADELFREIE MESOPROATIONEN
            </Link>
            <b style={{ textDecoration: "none", color: "#707070", marginLeft: "10px" }}>
                      |
            </b>

            <br></br>

            <Link to="/treatments/facials" style={{ textDecoration: "none", color: "#707070", marginLeft: "10px" }}>
                      FACIALS
            </Link>
            <b style={{ textDecoration: "none", color: "#707070", marginLeft: "10px" }}>
                      |
            </b>

            <Link to="/treatments/fruchtsäurepeeling" style={{ textDecoration: "none", color: "#707070", marginLeft: "10px" }}>
                      FRUCHTSÄUREPEELING
            </Link>
            <b style={{ textDecoration: "none", color: "#707070", marginLeft: "10px" }}>
                      |
            </b>

            <br></br>

            <Link to="/treatments/augenbrauen" style={{ textDecoration: "none", color: "#707070", marginLeft: "10px" }}>
                      AUGENBRAUEN & WIMPERN
            </Link>

            <b style={{ textDecoration: "none", color: "#707070", marginLeft: "10px" }}>
                      |
            </b>

            <Link to="/treatments/haarentfernung" style={{ textDecoration: "none", color: "#707070", marginLeft: "10px" }}>
                      HAARENTFERNUNG
            </Link>

            <br></br>
            <br></br>
            <br></br>


          </div>
            <div className={splitClasses}>
            

              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-left" data-reveal-container=".split-item">
                    CESUR SPECIALS
                  </h3>
                  <p className="m-0 reveal-from-left" data-reveal-container=".split-item" data-reveal-delay="100">
                    Mit unseren Cesur Special Paketen bieten wir Ihnen ein ganz persönliches Behandlungs sowie Beratungspaket an.
                  </p>
                  <Link to="/treatments/specials" style={{ textDecoration: "none" }}>
                    <p className="m-0 reveal-from-left" data-reveal-container=".split-item" data-reveal-delay="100" style={{ textAlign: "center",  marginTop: "40px"}}>
                      ➥ Zu den Specials
                  </p>
                  </Link>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-from-right',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200">
                  <Image
                    src={require('./../../assets/images/cesur/specials.jpg')}
                    alt="Features split top 01"
                    width={654}
                    height={522} />
                </div>
              </div>

              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-right" data-reveal-container=".split-item">
                    Ob Medical oder Normal Skincare
                  </h3>

                  <p className="m-0 reveal-from-right" data-reveal-container=".split-item" data-reveal-delay="100">
                    Bei uns finden Sie eine erstklassige Auswahl an Beauty-Behandlungen wie z.B. Hydrafacial, Micro Needeling, BB-Glow, Diamant Microdermabrasion, Fruchtsäurepeeling und viele mehr.
                  </p>
                  <Link to="/treatments" style={{ textDecoration: "none" }}>
                    <p className="m-0 reveal-from-left" data-reveal-container=".split-item" data-reveal-delay="100" style={{ textAlign: "center", marginTop: "40px" }}>
                      ➥ Zu den Behandlungen
                  </p>
                  </Link>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-from-left',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200">
                  <Image
                    src={require('./../../assets/images/cesur/skincare.jpg')}
                    alt="Features split top 02"
                    width={654}
                    height={521} />
                </div>
              </div>

              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-left" data-reveal-container=".split-item">
                    Dauerhafte und sanfte Haarentfernung
                  </h3>
                  <p className="m-0 reveal-from-left" data-reveal-container=".split-item" data-reveal-delay="100">
                    Glatte, strahlende Haut zur jeder Jahreszeit.
                  </p>
                  <Link to="/treatments/haarentfernung" style={{ textDecoration: "none" }}>
                    <p className="m-0 reveal-from-left" data-reveal-container=".split-item" data-reveal-delay="100" style={{ textAlign: "center", marginTop: "40px" }}>
                      ➥ Zu den Behandlungen
                  </p>
                  </Link>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile illustration-element-04 reveal-from-right',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200">
                  <Image
                    src={require('./../../assets/images/cesur/haarentfernung.jpg')}
                    alt="Features split top 03"
                    width={654}
                    height={436} />
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    );
  }
}

FeaturesSplitHome.propTypes = propTypes;
FeaturesSplitHome.defaultProps = defaultProps;

export default FeaturesSplitHome;