import React from 'react';
// import sections
import TeamContact from '../components/sections/TeamContact';
import GenericSection from '../components/sections/GenericSection';
import FeaturesSplitContact2 from '../components/sections/FeatureSplitContact2';
import './css/Google-Maps.css';

class Contact extends React.Component {

  state = {
    demoModalActive: false
  }

  openModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: true });
  }

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: false });
  }

  render() {

    return (
      <React.Fragment>
        <FeaturesSplitContact2 />
        <TeamContact />

        <GenericSection>
          <div className="responsiveContainer">
            <figure>
              <iframe title="Contact" style={{width: "1280px", height: "450px", frameborder: "0", border: "none" }} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2427.270864901954!2d13.406073515681854!3d52.52853327981585!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a851e25cebddb5%3A0x9446779bfa4a016d!2sLinienstra%C3%9Fe%2045%2C%2010119%20Berlin!5e0!3m2!1sde!2sde!4v1640884489412!5m2!1sde!2sde">
              </iframe>
            </figure>
          </div>
        </GenericSection>
      </React.Fragment>
    );
  }
}

export default Contact;