import React from 'react';
import classNames from 'classnames';
import {SectionSplitProps} from '../../utils/SectionProps';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

class FeaturesSplit02 extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'features-split section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'features-split-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const splitClasses = classNames(
      'split-wrap',
      invertMobile && 'invert-mobile',
      invertDesktop && 'invert-desktop',
      alignTop && 'align-top'
    );

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <div className={splitClasses}>

              <div className="split-item">
                <div style={{ width: "528px" }} data-reveal-container=".split-item" className="split-item-content center-content-mobile reveal-from-left">

                  <Image
                    src={require('./../../assets/images/cesur/behandlungsraum.jpg')}
                    alt="Cesur Treatment Room"
                    width={528}
                    height={396} />

                  {/* <h3 className="mt-0 mb-16 reveal-from-left" data-reveal-container=".split-item">
                    Cesur
                  </h3>
                  <p className="m-0 reveal-from-left" data-reveal-container=".split-item" data-reveal-delay="100">
                    Some Text about Nazmyie.
                  </p> */}
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-from-right',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item"
                //data-reveal-delay="200"
                >


                  <Image
                    src={require('./../../assets/images/cesur/hydrafacial.jpg')}
                    alt="Cesur Treatment Room"
                    width={528}
                    height={396} />



                  {/*                   <Carousel>
                    <CarouselItem>
                      <Image
                        src={require('./../../assets/images/Cesur_DE_Douglas_GmbH_fbbf23f3-05.jpg')}
                        alt="Cesur Treatment Room"
                        width={528}
                        height={396} />
                    </CarouselItem>

                    <CarouselItem>
                      <Image
                        src={require('./../../assets/images/Cesur_DE_Douglas_GmbH_fbbf23f3-12.jpg')}
                        alt="Hydrafacial"
                        width={528}
                        height={396} />
                    </CarouselItem>

                    <CarouselItem>
                    <Image
                        src={require('./../../assets/images/nazmiye-cesur-foto.1024x1024.jpg')}
                        alt="Hydrafacial"
                        width={528}
                        height={396} />
                    </CarouselItem>

                    <CarouselItem>
                    <Image
                        src={require('./../../assets/images/cesur-deko.jpg')}
                        alt="Hydrafacial"
                        width={528}
                        height={396} />
                    </CarouselItem>
                  </Carousel> */}

                  <div style={imgStyle}>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const imgStyle = {
  position: 'absolute',
  width: '118.18%',
  maxWidth: '118.18%',
  top: '-7.07%',
  left: '-9.09%'
}

FeaturesSplit02.propTypes = propTypes;
FeaturesSplit02.defaultProps = defaultProps;

export default FeaturesSplit02;