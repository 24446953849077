import React from 'react';
import classNames from 'classnames';
import {SectionSplitProps} from '../../utils/SectionProps';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

class FeaturesSplitTreatments extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'features-split section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'features-split-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const splitClasses = classNames(
      'split-wrap',
      invertMobile && 'invert-mobile',
      invertDesktop && 'invert-desktop',
      alignTop && 'align-top'
    );

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <div className={splitClasses}>

              <div className="split-item">
                <div style={{ maxWidth: "428px" }} className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-left" data-reveal-container=".split-item">
                    Behandlungen
                  </h3>
                  <p className="m-0 reveal-from-left" data-reveal-container=".split-item" data-reveal-delay="100">
                    Verwöhnen Sie sich und Ihre Haut mit unseren speziellen Behandlungen.
                    Lassen Sie sich von uns persönlich beraten und finden Sie für Ihre Haut die passende Behandlung und Pflege.
                  </p>

                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile illustration-element-01 reveal-from-left',
                    imageFill && 'split-item-image-fill',

                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200">
                  <Image style={{ width: "85%" }}
                    src={require('./../../assets/images/cesur/behandlungen.jpg')}
                    alt="Perfect Skin" />
                  <div style={imgStyle}>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const imgStyle = {
  position: 'absolute',
  width: '118.18%',
  maxWidth: '118.18%',
  top: '-7.07%',
  left: '-9.09%'
}

FeaturesSplitTreatments.propTypes = propTypes;
FeaturesSplitTreatments.defaultProps = defaultProps;

export default FeaturesSplitTreatments;