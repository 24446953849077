import React from 'react';
import classNames from 'classnames';
import {SectionProps} from '../../utils/SectionProps';
import Image from '../elements/Image';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

class Clients extends React.Component {

  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'clients section reveal-fade',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'clients-inner section-inner',
      // topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div
            className={innerClasses}
          >
            <ul className="list-reset">
              <li className="reveal-rotate-from-left">
                <Image
                  src={require('./../../assets/images/partner/svg/logo-hydrafacial.svg')}
                  alt="Hydrafacial"
                  width={158}
                  height={31} />
              </li>
              <li className="reveal-rotate-from-left" data-reveal-delay="300">
                <Image
                  src={require('./../../assets/images/partner/svg/logo-klapp.svg')}
                  alt="Klapp"
                  width={148}
                  height={26} />
              </li>
              <li className="reveal-rotate-from-left" data-reveal-delay="450">
                <Image
                  src={require('./../../assets/images/partner/svg/logo-health.svg')}
                  alt="Perk"
                  width={158}
                  height={31} />
              </li>
              <li className="reveal-rotate-from-left" data-reveal-delay="600">
                <Image style={{ paddingBottom: "10px"}}
                  src={require('./../../assets/images/partner/svg/logo-perk.svg')}
                  alt="Health Cosmetical"
                  width={148}
                  height={16} />
              </li>
              <li className="reveal-rotate-from-left" data-reveal-delay="600">
                <Image style={{ paddingBottom: "10px"}}
                  src={require('./../../assets/images/partner/jpg/logo-ddg.jpg')}
                  alt="DR Dennis Gross"
                  width={148}
                  height={16} />
              </li>
              <li className="reveal-rotate-from-left" data-reveal-delay="600">
                <Image style={{ paddingBottom: "10px"}}
                  src={require('./../../assets/images/partner/jpg/logo-deciem.jpg')}
                  alt="DR Dennis Gross"
                  width={148}
                  height={16} />
              </li>
            </ul>
          </div>
        </div>
      </section>
    );
  }
}

Clients.propTypes = propTypes;
Clients.defaultProps = defaultProps;

export default Clients;