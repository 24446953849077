import React from 'react';
import HeroPricelist from '../components/sections/HeroPricelist';
import GenericSection from '../components/sections/GenericSection';
import Cta from '../components/sections/Cta';
// import css
import './css/Pricelist.css';
// import section header
import SectionHeader from '../components/sections/partials/SectionHeader';

class Pricelist extends React.Component {

    render() {

        const genericSection04Header = {
            title: 'Get the best skin of your life.'
        }

        return (
            <React.Fragment>
                <HeroPricelist hasBgColor invertColor />
                <GenericSection>
                    <div className="container-xs">
                        <SectionHeader data={genericSection04Header} className="center-content" />
                        <h4>CESUR Beratung</h4>
                        <div id="textbox">
                            <p class="alignleft">Basic Beratung</p>
                            <p class="alignright">25€</p>
                        </div>
                        <div style={{ clear: 'both' }}></div>
                    </div>
                </GenericSection>

                <GenericSection topDivider>
                    <div className="container-xs">
                        <h4>CESUR Specials</h4>
                        <div id="textbox">
                            <p class="alignleft">Hydrafacial Combi D.Microdermabrasion</p>
                            <p class="alignright">179€</p>
                        </div>
                        <div style={{ clear: 'both' }}></div>

                        <div id="textbox">
                            <p class="alignleft">Diamant Microdermabrasion Combi Glow Peel</p>
                            <p class="alignright">119€</p>
                        </div>
                        <div style={{ clear: 'both' }}></div>

                        <div id="textbox">
                            <p class="alignleft">Basic Facial Combi Glow Peel</p>
                            <p class="alignright">99€</p>
                        </div>
                        <div style={{ clear: 'both' }}></div>

                        <div id="textbox">
                            <p class="alignleft">Basic Facial mit ASA Peeling</p>
                            <p class="alignright">109€</p>
                        </div>
                        <div style={{ clear: 'both' }}></div>

                    </div>
                </GenericSection>

                <GenericSection topDivider>
                    <div className="container-xs" style={{ paddingTop: '0px' }}>
                        <h4>Hydrafacial</h4>
                        <div id="textbox">
                            <p class="alignleft">Hydrafacial Gesichtsbehandlung</p>
                            <p class="alignright">159€</p>
                        </div>
                        <div style={{ clear: 'both' }}></div>

                        <div id="textbox">
                            <p class="alignleft">Hydrafacial Gesichtsbehandlung inkl. Hals & Dekolleté</p>
                            <p class="alignright">199€</p>
                        </div>
                        <div style={{ clear: 'both' }}></div>

                        <div id="textbox">
                            <p class="alignleft">Hydrafacial Circadia Fleming Peptide Maske</p>
                            <p class="alignright">289€</p>
                        </div>
                        <div style={{ clear: 'both' }}></div>

                        <div id="textbox">
                            <p class="alignleft">Hydrafacial mit Wirkstoffampulle</p>
                            <p class="alignright">189€</p>
                        </div>
                        <div style={{ clear: 'both' }}></div>
                    </div>
                </GenericSection>

                <GenericSection topDivider>
                    <div className="container-xs">
                        <h4>Medical Facials</h4>
                        <div id="textbox">
                            <p class="alignleft">Microneedling</p>
                            <p class="alignright">169€</p>
                        </div>
                        <div style={{ clear: 'both' }}></div>

                        <div id="textbox">
                            <p class="alignleft">Microneedling inkl. Hals & Dekolleté</p>
                            <p class="alignright">199€</p>
                        </div>
                        <div style={{ clear: 'both' }}></div>

                        <div id="textbox">
                            <p class="alignleft">Radiofrequenz-Microneedling</p>
                            <p class="alignright">399€</p>
                        </div>
                        <div style={{ clear: 'both' }}></div>

                        <div id="textbox">
                            <p class="alignleft">Diamant Microdermabrasion</p>
                            <p class="alignright">99€</p>
                        </div>
                        <div style={{ clear: 'both' }}></div>
                    </div>
                </GenericSection>

                <GenericSection topDivider>
                    <div className="container-xs">
                        <h4>Facials</h4>
                        <div id="textbox">
                            <p class="alignleft">Basic</p>
                            <p class="alignright">79€</p>
                        </div>
                        <div style={{ clear: 'both' }}></div>

                        <div id="textbox">
                            <p class="alignleft">Medium</p>
                            <p class="alignright">89€</p>
                        </div>
                        <div style={{ clear: 'both' }}></div>

                        <div id="textbox">
                            <p class="alignleft">Premium</p>
                            <p class="alignright">109€</p>
                        </div>
                        <div style={{ clear: 'both' }}></div>

                        <div id="textbox">
                            <p class="alignleft">Nadelfreie Mesoproationen</p>
                            <p class="alignright">99€</p>
                        </div>
                        <div style={{ clear: 'both' }}></div>

                        <div id="textbox">
                            <p class="alignleft">Fruchtsäurepeeling ASA peel KLAPP</p>
                            <p class="alignright">109€</p>
                        </div>
                        <div style={{ clear: 'both' }}></div>
                    </div>
                </GenericSection>

                <GenericSection topDivider>
                    <div className="container-xs">
                        <h4>Augenbrauen & Wimpern</h4>
                        <div id="textbox">
                            <p class="alignleft">Augenbrauen Waxing/Threading</p>
                            <p class="alignright">15€</p>
                        </div>
                        <div style={{ clear: 'both' }}></div>

                        <div id="textbox">
                            <p class="alignleft">Augenbrauen Färben</p>
                            <p class="alignright">15€</p>
                            </div>
                        <div style={{ clear: 'both' }}></div>

                        <div id="textbox">
                            <p class="alignleft">Wimpern Färben</p>
                            <p class="alignright">15€</p>
                            </div>
                        <div style={{ clear: 'both' }}></div>

                        <div id="textbox">
                            <p class="alignleft">Wimpernlifting mit Färben</p>
                            <p class="alignright">69€</p>
                        </div>
                        <div style={{ clear: 'both' }}></div>

                        <div id="textbox">
                            <p class="alignleft">Browlifting mit Keratinboost</p>
                            <p class="alignright">69€</p>
                        </div>
                        <div style={{ clear: 'both' }}></div>

                        <div id="textbox">
                            <p class="alignleft">Henna Brow</p>
                            <p class="alignright">39€</p>
                        </div>
                        <div style={{ clear: 'both' }}></div>

                        <div id="textbox">
                            <p class="alignleft">Oberlippen Waxing/Threading</p>
                            <p class="alignright">ab 8€</p>
                            </div>
                        <div style={{ clear: 'both' }}></div>
                    </div>
                </GenericSection>

                <GenericSection topDivider>
                    <div className="container-xs">
                        <h4>Permanent Make-Up</h4>
                        <p style={{fontWeight: 'bold'}}> Augen </p>
                        <div id="textbox">
                            <p class="alignleft">Lidstrich</p>
                            <p class="alignright">ab 349€</p>
                        </div>
                        <div style={{ clear: 'both' }}></div>

                        <div id="textbox">
                            <p style={{fontWeight: 'bold'}}> Augenbrauen </p>
                            <p class="alignleft">Puderbrows</p>
                            <p class="alignright">ab 459€</p>
                        </div>
                        <div style={{ clear: 'both' }}></div>

                        <div id="textbox">
                            <p class="alignleft">Microblading</p>
                            <p class="alignright">ab 459€</p>
                        </div>
                        <div style={{ clear: 'both' }}></div>

                        <div id="textbox">
                            <p class="alignleft">Wimpernkranz oben oder unten</p>
                            <p class="alignright">ab 299€</p>
                        </div>
                        <div style={{ clear: 'both' }}></div>

                        <p style={{fontWeight: 'bold'}}> Lippen </p>
                        <div id="textbox">
                            <p class="alignleft">Lippen wie Aquarell</p>
                            <p class="alignright">ab 499€</p>
                        </div>
                        <div style={{ clear: 'both' }}></div>

                    <div id="textbox">
                            <p class="alignleft">Lippen Vollschattierung</p>
                            <p class="alignright">ab 549€</p>
                        </div>
                        <div style={{ clear: 'both' }}></div>

                        <div id="textbox">
                            <p class="alignleft">3 Zonen PMU</p>
                            <p class="alignright">ab 999€</p>
                        </div>
                        <div style={{ clear: 'both' }}></div>
                        </div>
                    
                </GenericSection>

                <GenericSection topDivider>
                    <div className="container-xs">
                        <h4>Laser - Dauerhafte Haarentfernung</h4>
                        <div id="textbox">
                            <p class="alignleft">Achseln</p>
                            <p class="alignright">69€</p>
                        </div>
                        <div style={{ clear: 'both' }}></div>

                        <div id="textbox">
                            <p class="alignleft">Oberlippe & Kinn</p>
                            <p class="alignright">39€</p>
                        </div>
                        <div style={{ clear: 'both' }}></div>

                        <div id="textbox">
                            <p class="alignleft">Gesicht</p>
                            <p class="alignright">49€</p>
                        </div>
                        <div style={{ clear: 'both' }}></div>

                        <div id="textbox">
                            <p class="alignleft">Nacken</p>
                            <p class="alignright">69€</p>
                        </div>
                        <div style={{ clear: 'both' }}></div>

                        <div id="textbox">
                            <p class="alignleft">Bikini (Nur Damen)</p>
                            <p class="alignright">99€</p>
                        </div>
                        <div style={{ clear: 'both' }}></div>

                        <div id="textbox">
                            <p class="alignleft">Bikini & Intim (Nur Damen)</p>
                            <p class="alignright">149€</p>
                        </div>
                        <div style={{ clear: 'both' }}></div>

                        <div id="textbox">
                            <p class="alignleft">Bauch</p>
                            <p class="alignright">79€</p>
                        </div>
                        <div style={{ clear: 'both' }}></div>

                        <div id="textbox">
                            <p class="alignleft">Beine oben</p>
                            <p class="alignright">99€</p>
                        </div>
                        <div style={{ clear: 'both' }}></div>

                        <div id="textbox">
                            <p class="alignleft">Beine unten</p>
                            <p class="alignright">99€</p>
                        </div>
                        <div style={{ clear: 'both' }}></div>

                        <div id="textbox">
                            <p class="alignleft">Brust</p>
                            <p class="alignright">99€</p>
                        </div>
                        <div style={{ clear: 'both' }}></div>

                        <div id="textbox">
                            <p class="alignleft">Rücken</p>
                            <p class="alignright">129€</p>
                        </div>
                        <div style={{ clear: 'both' }}></div>
                    </div>
                </GenericSection>

                <GenericSection topDivider>
                    <div className="container-xs">
                        <h4>Bleaching</h4>
                        <div id="textbox">
                            <p class="alignleft">Zahnbleaching</p>
                            <p class="alignright">ab 79€</p>
                        </div>
                        <div style={{ clear: 'both' }}></div>
                    </div>
                </GenericSection>
                <Cta hasBgColor invertColor split className="has-bg-color-cut" />
            </React.Fragment>
        );
    }
}

export default Pricelist;