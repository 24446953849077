import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
    ...SectionTilesProps.types
}

const defaultProps = {
    ...SectionTilesProps.defaults
}

class TeamContact extends React.Component {

    render() {

        const {
            className,
            topOuterDivider,
            bottomOuterDivider,
            topDivider,
            bottomDivider,
            hasBgColor,
            invertColor,
            pushLeft,
            ...props
        } = this.props;

        const outerClasses = classNames(
            'team section center-content',
            topOuterDivider && 'has-top-divider',
            bottomOuterDivider && 'has-bottom-divider',
            hasBgColor && 'has-bg-color',
            invertColor && 'invert-color',
            className
        );

        const innerClasses = classNames(
            'team-inner section-inner',
            topDivider && 'has-top-divider',
            bottomDivider && 'has-bottom-divider'
        );

        const tilesClasses = classNames(
            'tiles-wrap',
            pushLeft && 'push-left'
        );

        const sectionHeader = {
            title: 'Get in touch with us',
            //paragraph: 'Lorem ipsum dolor sidquis nostrud exercitation.'
        };

        return (
            <section
                {...props}
                className={outerClasses}
            >
                <div className="container">
                    <div className={innerClasses}>
                        <SectionHeader data={sectionHeader} className="center-content reveal-from-bottom" />
                        <div className={tilesClasses}>

                            <div className="tiles-item reveal-from-bottom" data-reveal-container=".tiles-wrap">
                                <div className="tiles-item-inner">
                                    <div className="team-item-header">
                                        <div className="team-item-image mb-24">
                                            <Image
                                                src={require('./../../assets/images/layout/icon-location-contact.svg')}
                                                alt="Cesur-Location"
                                                width={30}
                                                height={30} />
                                        </div>
                                    </div>
                                    <div className="team-item-content">
                                        <h5 className="team-item-name mt-0 mb-4">
                                            Visit Us
                                        </h5>
                                        <p style={{ paddingTop: "20px" }} className="m-0 text-sm">
                                            CESUR Berlin <br></br>
                                        Linienstraße 45 <br></br>
                                        10119 Berlin
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="tiles-item reveal-from-bottom" data-reveal-container=".tiles-wrap" data-reveal-delay="150">
                                <div className="tiles-item-inner">
                                    <div className="team-item-header">
                                        <div className="team-item-image mb-24">
                                            <Image
                                                src={require('./../../assets/images/layout/icon-phone-contact.svg')}
                                                alt="Cesur-Phone"
                                                width={30}
                                                height={30} />
                                        </div>
                                    </div>
                                    <div className="team-item-content">
                                        <h5 className="team-item-name mt-0 mb-4">
                                            Call Us
                                        </h5>
                                        <p style={{ paddingTop: "20px" }} className="m-0 text-sm">
                                            <a style={{ textDecoration: "none", color: "#707070" }} href="tel:+49-30-257-630-20">030 25763020</a>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="tiles-item reveal-from-bottom" data-reveal-container=".tiles-wrap" data-reveal-delay="300">
                                <div className="tiles-item-inner">
                                    <div className="team-item-header">
                                        <div className="team-item-image mb-24">
                                            <Image
                                                src={require('./../../assets/images/layout/icon-mail-contact.svg')}
                                                alt="Cesur-Mail"
                                                width={30}
                                                height={30} />
                                        </div>
                                    </div>
                                    <div className="team-item-content">
                                        <h5 className="team-item-name mt-0 mb-4">
                                            Mails Us
                                        </h5>
                                        <p style={{ paddingTop: "20px" }} className="m-0 text-sm">
                                            <a style={{ textDecoration: "none", color: "#707070" }} href="mailto: hello@cesur-berlin.de">
                                                hello@cesur-berlin.de
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

TeamContact.propTypes = propTypes;
TeamContact.defaultProps = defaultProps;

export default TeamContact;