import React from 'react';
// import sections
import GenericSection from '../components/sections/GenericSection';


class Imprint extends React.Component {

  state = {
    demoModalActive: false
  }

  openModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: true });
  }

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: false });
  }

  render() {

    return (
      <React.Fragment>
        <GenericSection topDivider className="illustration-section-06">
          <div>
            <h2 className="mt-0">Impressum</h2>
            <p>
              
            </p>
            <p>
            Angaben gemäß §5 TMG:
            </p>
            <p>
            Cesur GmbH <br></br>
            Linienstr. 45 <br></br>
            10119 Berlin <br></br>
            HRB 185990 B<br></br>
            USt-IdNr: DE311458463<br></br>
            </p>
            <h4>Geschäftsführerin</h4>
            <p>
            Nazmiye Cesur
            </p>
            <h4>Kontakt</h4>
            <p>
            Nazmiye Cesur <br></br>
            Cesur GmbH <br></br>
            Linienstr. 45 <br></br>
            10119 Berlin <br></br>
            Telefon: +49 30 257 630 20<br></br>
            E-Mail: hello@cesur-berlin.de<br></br>
            </p>
            <h4>Kreative Umsetzung</h4>
            <p>Maximilian Kroschewski & Anne Radunski</p>
          </div>
        </GenericSection>
      </React.Fragment>
    );
  }
}

export default Imprint;