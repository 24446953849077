import React from 'react';
import {Switch, withRouter} from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';
// Layouts
import LayoutDefault from './layouts/LayoutDefault';
import LayoutPricelist from './layouts/LayoutPricelist';
import LayoutTreatments from './layouts/LayoutTreatments';
import LayoutContact from './layouts/LayoutContact';
import LayoutAlternative from './layouts/LayoutAlternative';
import LayoutImprint from './layouts/LayoutImprint';
import LayoutPrivacy from './layouts/LayoutPrivacy';
// Views
import Home from './views/Home';
import Pricelist from './views/Pricelist';
import Secondary from './views/Secondary';
import Treatments from './views/Treatments';
import Imprint from './views/Imprint';
import Privacy from './views/Privacy';
import Contact from './views/Contact';

class App extends React.Component {

  componentDidMount() {
    document.body.classList.add('is-loaded')
    this.refs.scrollReveal.init();
  }

  // Route change
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
      this.refs.scrollReveal.init();
    }
  }
  
  render() {
    return (
      <ScrollReveal
        ref="scrollReveal"
        children={() => (
          <Switch>
            <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
            <AppRoute exact path="/secondary" component={Secondary} layout={LayoutAlternative} />
            <AppRoute exact path="/pricelist" component={Pricelist} layout={LayoutPricelist} />
            <AppRoute exact path="/treatments" component={Treatments} layout={LayoutTreatments} />
            <AppRoute exact path="/imprint" component={Imprint} layout={LayoutImprint} />
            <AppRoute exact path="/contact" component={Contact} layout={LayoutContact} />
            <AppRoute exact path="/treatments/:treatment" component={Treatments} layout={LayoutTreatments} />
            <AppRoute exact path="/privacy" component={Privacy} layout={LayoutPrivacy} />
          </Switch>
        )} />
    );
  }
}

export default withRouter(props => <App {...props} />);