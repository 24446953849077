import React from 'react';
import classNames from 'classnames';
import {SectionSplitProps} from '../../utils/SectionProps';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

class FeaturesSplit extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'features-split section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'features-split-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const splitClasses = classNames(
      'split-wrap',
      invertMobile && 'invert-mobile',
      invertDesktop && 'invert-desktop',
      alignTop && 'align-top'
    );

    // const sectionHeader = {
    //   title: 'Great skin does not happen by chance but by appointment.',
    //   paragraph: ''
    // };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            {/* <SectionHeader data={sectionHeader} className="center-content" /> */}
            <div className={splitClasses}>

              <div className="split-item" style={{ paddingBottom: "98px"}}>
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-left" data-reveal-container=".split-item">
                    Willkommen bei  Ihrem Boutique-Studio Cesur Berlin
                  </h3>
                  <p className="m-0 reveal-from-left" data-reveal-container=".split-item" data-reveal-delay="100">
                    Wir sind Ihre Spezialisten für innovative Haut- und Laserbehandlungen.
                  </p>

                  <p style={{ paddingTop: "15px" }} className="m-0 reveal-from-left" data-reveal-container=".split-item" data-reveal-delay="100">
                    Schöne, gesunde Haut ist kein Zufall, sondern das Ergebnis der richtigen Behandlung. Unsere Experten beraten Sie ausführlich und individuell, um die für Ihre Haut optimale Methode zu ermitteln.
                  </p>

                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile illustration-element-home',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200">
                  <Image //style={{ borderStyle: "solid", borderWidth: "0.7em", borderColor: "#B2B4A6" }}
                    src={require('./../../assets/images/cesur/cesura-11.jpg')}
                    alt="Skin" />
                  <div style={imgStyle}>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const imgStyle = {
  position: 'absolute',
  width: '118.18%',
  maxWidth: '118.18%',
  top: '-7.07%',
  left: '-9.09%'
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;