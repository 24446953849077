import React from 'react';
// import sections
import GenericSection from '../components/sections/GenericSection';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Clients from '../components/sections/Clients';
import News from '../components/sections/News';
import FeaturesTabs from '../components/sections/FeaturesTabs';
import Service from '../components/sections/Service';
import FeaturesSplitHome from '../components/sections/FeaturesSplitHome';
// import some required elements
import HeroHome from '../components/sections/HeroHome';

function Home() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Page content */}
      <main className="flex-grow">

        {/*  Page sections */}
        <HeroHome invertColor />
        <Service />
        <FeaturesSplit invertMobile imageFill bottomDivider />
        <FeaturesSplitHome invertMobile imageFill bottomDivider /*className="mt-0 mb-16 illustration-section-home" */ />
        <GenericSection>
        </GenericSection>
        <News />
        <br></br>
        <Clients topDivider bottomDivider />
        <br></br>
        <br></br>
        <FeaturesTabs className="illustration-section-01" />
        {/* <FeatureSplitContact /> */}

        <GenericSection className="center-content">
          <div className="container-xs">
            <h2 className="mt-0 mb-32 reveal-from-top" style={{ fontFamily: "Architects Daughter", fontStyle: "italic", color: "#333333" }}>Wir freuen uns auf Sie</h2>
          </div>
        </GenericSection>

      </main>

    </div>
  );
}

export default Home;
