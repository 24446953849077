import React from 'react';
import classNames from 'classnames';
import {SectionProps} from '../../utils/SectionProps';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

class HeroHome extends React.Component {

  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'hero section center-content',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    return (
      <section

        {...props}
        className={outerClasses}
      >
        <img src={require('../../assets/images/cesur/cesur-home.jpg')} alt="Cesur Home" />

      </section>
    );
  }
}

HeroHome.propTypes = propTypes;
HeroHome.defaultProps = defaultProps;

export default HeroHome;
