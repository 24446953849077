import React from 'react';
import classNames from 'classnames';
import {SectionSplitProps} from '../../utils/SectionProps';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

class FeaturesSplitCesurAcademy extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'features-split section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'features-split-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const splitClasses = classNames(
      'split-wrap',
      invertMobile && 'invert-mobile',
      invertDesktop && 'invert-desktop',
      alignTop && 'align-top'
    );

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div style={{ textAlign: "left" }} className="container">
          <div className={innerClasses}>
            <div className={splitClasses}>

              <div style={{ paddingTop: "0px" }} className="split-item">
                <div style={{ maxWidth: "528px" }} className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-left" data-reveal-container=".split-item">
                    Cesur Academy
                  </h3>
                  <p className="m-0 reveal-from-left" data-reveal-container=".split-item" data-reveal-delay="100">
                  Hautpflege ist eine persönliche Reise, und wir sind hier, um Sie auf diesem Weg zu begleiten.
                  Sind Sie bereit für eine neue Herausforderung? Dann bleiben Sie gespannt. 
                  Es erwartet Sie eine neue Reise, die nicht nur Sie zum strahlen bringt.
                  </p>

                  <p className="m-0 reveal-from-left" data-reveal-container=".split-item" data-reveal-delay="100" style={{marginTop: "20px"}}>
                  Bei Fragen melden Sie sich gerne bei uns im Geschäft.
                  </p>



                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile illustration-element-02 reveal-from-left',
                    imageFill && 'split-item-image-fill',

                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200">
                  <Image style={{ width: "55%" }}
                    src={require('./../../assets/images/cesur/academy.jpg')}
                    alt="Perfect Skin" />
                  <div style={imgStyle}>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const imgStyle = {
  position: 'absolute',
  width: '118.18%',
  maxWidth: '118.18%',
  top: '-7.07%',
  left: '-9.09%'
}

FeaturesSplitCesurAcademy.propTypes = propTypes;
FeaturesSplitCesurAcademy.defaultProps = defaultProps;

export default FeaturesSplitCesurAcademy;