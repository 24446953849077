import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Input from '../elements/Input';
import Button from '../elements/Button';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

class MessageForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      message: '',
      submitted: false
    };
  }

  changeHandler = (event) => {
    let nam = event.target.name;
    let val = event.target.value;
    this.setState({[nam]: val});
  }

  submitHandler (event) {
    event.preventDefault();
    this.setState({submitted: true});
    const data = new FormData(event.target);

    fetch('/contact.php', {
      method: 'POST',
      body: data,
    });
  }

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'signin section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'signin-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const { submitted, name } = this.state;

    return (
      <section
        {...props}
        className={outerClasses}
        style={{paddingTop: "1px"}}
      >
        <div className="container">
          <div className={innerClasses}>
            <div className="tiles-wrap">
              <div className="tiles-item">
                <div className="tiles-item-inner">

                  {!submitted && (

                    <form onSubmit={this.submitHandler.bind(this)}>
                    <fieldset>

                    <div className="mb-12">
                      <Input
                       type="text"
                       name="name"
                       placeholder="Name"
                       label="Name"
                       labelHidden
                       required
                       onChange={this.changeHandler} />
                     </div>

                      <div className="mb-12">
                        <Input
                          type="email"
                          name="email"
                          label="Email"
                          placeholder="Email"
                          labelHidden
                          required
                          onChange={this.changeHandler}/>
                      </div>

                      <div className="mb-12">
                        <Input
                          type="textarea"
                          name="message"
                          label="Message"
                          labelHidden
                          placeholder="Message"
                          required
                          onChange={this.changeHandler}/>
                      </div>

                      <div className="mt-24 mb-32">
                        <Button type="submit" color="primary" wide>Send Message</Button>
                      </div>

                    </fieldset>
                  </form>

                  )}

                  {submitted && (

                    <div className="container">
                      <p>
                        Vielen Dank, {name}.
                      </p>
                      <p>
                        Wir haben Deine Anfrage erhalten und melden uns schnellstmöglich bei Dir.
                      </p>
                    </div>

                  )}

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

MessageForm.propTypes = propTypes;
MessageForm.defaultProps = defaultProps;

export default MessageForm;